import { React,useContext,useEffect, useState, } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios'
// import jsdom from "jsdom";
import RightNav from './rightNav';
// import CIcon from '@coreui/icons-react';
// import {freeSet} from '@coreui/icons';
import{
    CRow, 
    CCol, 
    CContainer,
    CNavItem,
    CTabContent,
    CNavLink,
    CTabPane,
    CNav,
    CCardBody,
    CCardTitle,
    CCardSubtitle,
    CCardText,
    CCardImage,
    CCard,
    CButton,
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CFormLabel,
    CFormInput,
    CCardLink,
    CFormTextarea
} from '@coreui/react';
import { AuthContext } from '../context/AuthContext'

const CollectionsEdit = () => {
    const [activeKey, setActiveKey] = useState(1)
    const auth = useContext(AuthContext);
    const params = useParams()


    const [items, setItems] = useState([])
    const [visible, setVisible] = useState(false)

    const [wishlist, setWishlist] = useState({
        title:'',
        public: 0,
        note:''
    })

    const [newproduct, setNewproduct] = useState({
        title:'',
        url: '',
        image: '',
        note:''
    })

    const changeHandlerProduct = event => {
        setNewproduct({ ...newproduct, [event.target.name]: event.target.value })
    }

    const changeHandlerProductURL = event => {
        setNewproduct({ ...newproduct, url: event.target.value })

        axios.post(`https://${auth.ip}/wishlistmetadata`, {
            link: event.target.value
        })
        .then(function (response) {
            setNewproduct({ ...newproduct, 
                title: response.data.name,
                url: event.target.value,
                image: response.data.image[0]
            })
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });

        console.log(event.target.value)
    }

    const changeHandler = event => {
        setWishlist({ ...wishlist, [event.target.name]: event.target.value })
    }

    const createHandler = () => {
        console.log(params)
        axios.post(`https://${auth.ip}/addProduct`, {
            id: params.id,
            title: newproduct.title,
            url: newproduct.url,
            image: newproduct.image,
            note: newproduct.note
        })
        .then(function (response) {
            console.log(response);
            axios.post(`https://${auth.ip}/wishlistitems`,{
                _id: params.id,
            })
            .then(function(response){
                setItems(response.data[0].items);
                setVisible(false);
                setWishlist({
                    title:'',
                    public: 0,
                    note:''
                })
            })
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const deleteHandler = (id) => {
        // console.log(id)
        axios.post(`https://${auth.ip}/wishlist/delete`, {
            _id: id
        })
        .then(function (response) {
            console.log(response);
            axios.post(`https://${auth.ip}/wishlist/find`,{
                owner: auth.userId
            })
            .then(function(response){
                setItems(response.data);
            })
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        axios.post(`https://${auth.ip}/wishlistitems`,{
            _id: params.id,
        })
        .then(function(response){
            setItems(response.data[0].items);
        })
        console.log('asd' + auth.ip)
    }, [auth.ip])

    useEffect(() => {
        console.log(items)
    }, [items])

    useEffect(() => {
        console.log(wishlist)
    }, [wishlist])

    useEffect(() => {
        console.log(newproduct)
    }, [newproduct])

    return (
        <div>
            <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
                <CModalTitle>Add product</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CRow>
                    <CCol>
                        <CFormLabel htmlFor="staticEmail2">
                        Product url:
                        </CFormLabel>
                        <CFormInput
                            type="text"
                            name='url'
                            id="exampleFormControlInput1"
                            placeholder="..."
                            onChange={changeHandlerProductURL}
                        />
                    </CCol>
                </CRow>
                <br />
                <CRow>
                    <CCol>
                        <CFormLabel htmlFor="staticEmail2">
                        Product title:
                        </CFormLabel>
                        <CFormInput
                            type="text"
                            name='title'
                            id="exampleFormControlInput1"
                            placeholder="..."
                            onChange={changeHandlerProduct}
                            value={newproduct.title}
                        />
                    </CCol>
                </CRow>
                <br />
                <CRow>
                    <CCol>
                        <CFormLabel htmlFor="staticNote">
                        Product note(optional):
                        </CFormLabel>
                        <CFormTextarea
                            id="staticNote"
                            name="note"
                            label="Example textarea"
                            onChange={changeHandlerProduct}
                            rows={3}
                        ></CFormTextarea>
                    </CCol>
                </CRow>
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => setVisible(false)}>
                Close
                </CButton>
                <CButton 
                    color="primary" 
                    onClick={createHandler}
                >
                    Create
                </CButton>
            </CModalFooter>
            </CModal>
            {/* modals end */}
            <RightNav />

            <CContainer>
                <CRow>
                    <CCol>
                        <div className='wishlistTopBar'>
                            <div>
                                <h4>Wishlist Title:</h4>
                            </div>
                            <div>
                                <CButton color="primary" onClick={() => setVisible(!visible)}>Add product</CButton>
                            </div>
                        </div>
                    </CCol>
                </CRow>

                <CRow>
                    <CCol>    

                        <CTabContent>
                            <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 1}>
                                <br />

                                <CRow>
                                    {items.length > 0 &&
                                        items.map(item => {
                                            if(item)
                                            {
                                                return(
                                                    <CCol sm={6} className="itemCartWishlsit" >
                                                        <CCard className="mb-6">
                                                            <CRow className="g-0">
                                                                <CCol md={5}>
                                                                    <CCardImage src={item.image} className="cardImage" />
                                                                </CCol>
                                                                <CCol md={7} style={{alignItems:"center"}}>
                                                                    <CCardBody>
                                                                        <CCardTitle>{item.title}</CCardTitle>
                                                                        <CCardText>
                                                                            {item.note}
                                                                        </CCardText>
    
                                                                        <CCardText>
                                                                            <CCardLink target='_blank' href={item.url}>Open</CCardLink> 
                                                                            <button style={{float:'right'}} >Delete</button>
                                                                        </CCardText>
                                                                        
                                                                        <CCardText>
                                                                        <small className="text-medium-emphasis">Last updated 3 mins ago</small>
                                                                        </CCardText>
                                                                    </CCardBody>
                                                                </CCol>
                                                            </CRow>
                                                        </CCard>
                                                    </CCol>
                                                )
                                            }else{
                                                return(
                                                    <h1>blank</h1>
                                                )
                                            }
                                        })
                                    }
                                    
                                </CRow>
                            </CTabPane>
                        </CTabContent>

                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export default CollectionsEdit;