import React, {useContext, useState, useEffect} from 'react'
import axios from 'axios'
import { AuthContext } from '../context/AuthContext'
import avatar from '../img/profile.jpg'
import RightNav from './rightNav';
import { CForm, CAvatar,CFormInput,CRow, CCol, CContainer  } from '@coreui/react'

export const Profile = () => {

    const auth = useContext(AuthContext);

    const [cab, setCab] = useState(false);

    const [Lnav, setLnav] = useState(false);

    const logOutHandler = () => {
        auth.logout()
    }

    const [data, setData] = useState({
        name: '-',
        email: '-',
        telephone: '-'
    });

    useEffect(() => {
        axios.post(`https://${auth.ip}/user/${auth.userId}`)
        .then(function(response){
            setData({
                name: response.data.name,
                email: response.data.email,
                telephone: response.data.telephone,
            })
            // console.log(response)
        })
    }, [])

    // console.log(data)

    
    return(
        <div>
            <RightNav/>  
            <CContainer>
                <CRow>
                    <CCol>
                        <div className='wishlistTopBar'>
                            <div>
                                <h4>My account:</h4>
                            </div>

                            <div>
                                
                            </div>

                            <div>
                                
                            </div>
                        </div>
                    </CCol>
                </CRow>

                <CRow>
                    <CCol>
                        <div className='profileDivider'>
                            <div className='profileDividerBody'>
                                <div className='profileDividerTitle'>
                                    Profile information:
                                </div>

                                <CRow>
                                    <CCol md="6">
                                        <div className="profRightRow">
                                            <div className="profRightRowName">
                                                Name:
                                            </div>

                                            <div className="profRightRowVal">
                                                {data.name}
                                            </div>
                                        </div>
                                    </CCol>

                                    <CCol md="6">
                                        <div className="profRightRow">
                                            <div className="profRightRowName">
                                                E-mail:
                                            </div>

                                            <div className="profRightRowVal">
                                                {data.email}
                                            </div>
                                        </div>
                                        <div className="profRightRow">
                                            <div className="profRightRowName">
                                                Telephone number:
                                            </div>

                                            <div className="profRightRowVal">
                                                {data.telephone}
                                            </div>
                                        </div>
                                    </CCol>
                                </CRow>
                            </div>
                        </div>
                    </CCol>
                </CRow>

                <CRow>
                    <CCol>
                        <div className='profileDivider'>
                            <div className='profileDividerBody'>
                                <div className='profileDividerTitle'>
                                    Social links:
                                </div>

                                <CRow>
                                    <CCol md="6">
                                        <div className="profRightRow">
                                            <div className="profRightRowName">
                                                Instagram:
                                            </div>

                                            <div className="profRightRowVal">
                                                -
                                            </div>
                                        </div>
                                    </CCol>

                                    <CCol md="6">
                                        <div className="profRightRow">
                                            <div className="profRightRowName">
                                                Telegram:
                                            </div>

                                            <div className="profRightRowVal">
                                                -
                                            </div>
                                        </div>
                                    </CCol>
                                </CRow>
                            </div>
                        </div>
                    </CCol>
                </CRow>

                {/* <CRow>
                    <CCol md="2">
                        <div className="profileAvatar">
                            <CAvatar color="primary" textColor="white">
                                P
                            </CAvatar>
                        </div>
                    </CCol>
                </CRow> */}

                
            </CContainer>

            <div className="">
            
                <div className="">
                    

                    <div className="">

                        <div className="profRightBtns">

                            <button onClick={logOutHandler} className="profRightBtns2">Log out</button>

                        </div>

                    </div>
                </div>
            
            </div>

        </div>
    )
}

export default Profile