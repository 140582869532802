import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { LoginPage } from './pages/loginPage'
import { LoginPageMerchant } from './pages/loginPageMerchant'
import {ProfilePage} from './pages/profilePage'
import { RegisterPage } from './pages/registerPage'
import PublicWishlist from './components/publicWishlist'




export const useRoutes = (isAuthenticated, isAdmin) => {

    // const isAdmin = JSON.parse(localStorage.getItem('userData'))
    

    if (isAuthenticated) {
        
        return(
            <Switch>
                <Route path="/wishlist/:id" component={PublicWishlist}/>
                <Route path="/profile" component={ProfilePage}/>
                <Redirect to="/profile"/>
            </Switch>
        )
    }

    return(
        <Switch>
            <Route exact path="/auth/login" component={LoginPage}/>
            <Route exact path="/auth/login-merchant" component={LoginPageMerchant}/>
            <Route exact path="/auth/register" component={RegisterPage}/>
            <Route path="/wishlist/:id" component={PublicWishlist}/>
            <Redirect to="/auth/login"/>
        </Switch>
    )
}