import React, {useContext, useState, useEffect} from 'react'
import axios from 'axios'
import { AuthContext } from '../context/AuthContext'
import avatar from '../img/profile.jpg'
import RightNav from './rightNav';
import { CForm, CFormInput,CRow, CCol, CContainer  } from '@coreui/react'



export const Settings = () => {

    const auth = useContext(AuthContext);

    const [cab, setCab] = useState(false);

    const [Lnav, setLnav] = useState(false);

    const logOutHandler = () => {
        auth.logout()
    }

    const [data, setData] = useState({
        name: '-',
        email: '-',
        telephone: '-',
        newPassword: ' ',
        password: ' '
    });

    const changeHandler = event => {
        setData({ ...data, [event.target.name]: event.target.value })
    }


    useEffect(() => {
        axios.post(`https://${auth.ip}/user/${auth.userId}`)
        .then(function(response){
            setData({
                name: response.data.name,
                email: response.data.email,
                telephone: response.data.telephone,
            })
            // console.log(response)
        })
    }, [])

    const saveHandler = () => {
        axios.post(`https://localhost/userEdit`, {
            _id: auth.userId,
            name: data.name,
            email: data.email,
            telephone: data.telephone,
            newPassword: data.newPassword,
            password: data.password
        })
        .then(function(res){
            if (res.status === 200) {
                document.location.href = '/#/profile/info'
            }
        })
        .catch(function(){
            console.log('err')
        })
    }

    // console.log(data)

    
    return(
        <div>
            <RightNav/>  
            <CContainer>
                <CRow>
                    <CCol>
                        <div className='wishlistTopBar'>
                            <div>
                                <h4>Settings:</h4>
                            </div>

                            <div>
                                
                            </div>

                            <div>
                                
                            </div>
                        </div>
                    </CCol>
                </CRow>

                <CRow>
                    <CCol>
                        <div className='profileDivider'>
                            <div className='profileDividerBody'>
                                <div className='profileDividerTitle'>
                                    Profile information:
                                </div>

                                <CRow>
                                    <CCol md="6">
                                    <div className="profRightRow">
                                        <div className="profRightRowName">
                                            Name:
                                        </div>

                                        <CFormInput
                                            type="text"
                                            name="name"
                                            value={data.name}
                                            onChange={changeHandler}
                                        />
                                    </div>
                                    </CCol>

                                    <CCol md="6">
                                        <div className="profRightRow">
                                            <div className="profRightRowName">
                                                E-mail:
                                            </div>

                                            <CFormInput
                                                type="text"
                                                name="email"
                                                value={data.email}
                                                onChange={changeHandler}
                                            />
                                        </div>
                                        <div className="profRightRow">
                                            <div className="profRightRowName">
                                                Telephone number:
                                            </div>

                                            <CFormInput
                                                type="text"
                                                name="telephone"
                                                value={data.telephone}
                                                onChange={changeHandler}
                                            />
                                        </div>
                                    </CCol>
                                </CRow>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CContainer>

            <div className="">
            
                <div className="">
                    

                    <div className="">

                        <div className="profRightBtns">

                            <button onClick={saveHandler} className="profRightBtns2">Save</button>

                        </div>

                    </div>
                </div>
            
            </div>

        </div>
    )
}

export default Settings