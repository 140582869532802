import {useState, useCallback, useEffect} from 'react'

const storageName = 'userData'

export const useAuth = () =>{
    const [token, setToken] = useState(null)
    const [userId, setUserId] = useState(null)
    const [isAdmin, setAdmin] = useState(null)
    const ip = 'fyby-server-00e6ffc2a959.herokuapp.com';
    // const ip = '127.0.0.1:8080';


    const login = useCallback((jwtToken, id, Admin) => {
        setToken(jwtToken)
        setUserId(id)
        setAdmin(Admin)

        localStorage.setItem(storageName, JSON.stringify({
            userId: id, token: jwtToken, isAdmin: Admin
        }))

    }, [])

    const logout = useCallback(() => {
        setToken(null)
        setUserId(null)
        setAdmin(null)

        localStorage.removeItem(storageName)
        sessionStorage.removeItem(storageName)
    }, [])

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(storageName))

        if(data && data.token){
            login(data.token, data.userId, data.isAdmin)
        }
    }, [login])

    return {login, logout, token, userId, isAdmin, ip}
}