import React, {useContext, useState, useEffect} from 'react'
import axios from 'axios'
import {useLocation} from "react-router-dom";
import { AuthContext } from '../context/AuthContext'
import '../css/publicWishlist.css'
import{
    CRow, 
    CCol, 
    CCardBody,
    CCardTitle,
    CCardText,
    CCardImage,
    CCard,
    CCardLink,
} from '@coreui/react';


export const PublicWishlist = () => {

    const auth = useContext(AuthContext);
    const search = useLocation();
    const _id = search.pathname.replace("/wishlist/", "");
    const [items, setItems] = useState([])

    const [wishlist, setWishlist] = useState({
        title: 'Title',
        createdAt: 'createdAt',
        updatedAt: 'updatedAt'
    });


    useEffect(() => {
        axios.post(`https://${auth.ip}/wishlist/${_id}`,{
            _id: _id
        })
        .then(function(response){
            console.log(response)
            console.log(items)
            setItems(response.data.wishlist.items);
            setWishlist({...wishlist, 
                title: response.data.wishlist.title,
                createdAt: response.data.wishlist.createdAt,
                updatedAt: response.data.wishlist.updatedAt
            })
        })
    }, [])

    console.log(_id)
    console.log(wishlist)
    console.log(items)

    
    return(
        <div>
            <div className="rightBody">
                <div className="profwrapFluid">
                    <h1 className='pubWishlistTitle'>{wishlist.title}</h1>

                    <br/>

                    <div>
                        <CRow>
                            {items.length > 0 &&
                                items.map(item => {
                                    if(item)
                                    {
                                        return(
                                            <CCol sm={6} className="itemCartWishlsit" >
                                                <CCard className="mb-6">
                                                    <CRow className="g-0">
                                                        <CCol md={5}>
                                                            <CCardImage src={item.image} className="cardImage" />
                                                        </CCol>
                                                        <CCol md={7} style={{alignItems:"center"}}>
                                                            <CCardBody>
                                                                <CCardTitle>{item.title}</CCardTitle>
                                                                <CCardText>
                                                                    {item.note}
                                                                </CCardText>

                                                                <CCardText>
                                                                    <CCardLink target='_blank' href={item.url}>Open</CCardLink> 
                                                                    {/* <button style={{float:'right'}} >Delete</button> */}
                                                                </CCardText>
                                                                
                                                                <CCardText>
                                                                {/* <small className="text-medium-emphasis">Last updated 3 mins ago</small> */}
                                                                </CCardText>
                                                            </CCardBody>
                                                        </CCol>
                                                    </CRow>
                                                </CCard>
                                            </CCol>
                                        )
                                    }else{
                                        return(
                                            <h1>blank</h1>
                                        )
                                    }
                                })
                            }
                            
                        </CRow>
                    </div>

                    <br/>

                    <small>Created at: {new Date(wishlist.createdAt).toLocaleString("lookup")}</small><br />
                    <small>Updated at: {new Date(wishlist.updatedAt).toLocaleString("lookup")}</small>
                </div>
            </div>
        </div>
    )
}

export default PublicWishlist