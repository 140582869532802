import React from 'react';
import {
    CChart,
    CChartBar,
    CChartLine,
  } from '@coreui/react-chartjs';
import { 
    CRow, 
    CContainer, 
    CAlert
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {freeSet} from '@coreui/icons';
import RightNav from './rightNav';
import '@coreui/chartjs/dist/css/coreui-chartjs.css'

const dashboard = () => {

    return (
        <div>
            <RightNav/> <br/>

            <CContainer>
                <CRow>
                    <CAlert color="success">
                        We're thrilled to announce a special app demo that will change the way you create and manage your wishlists. <br />
                        Join us for an exclusive sneak peek of our revolutionary app. <br />
                        Discover powerful features designed to make wishlist organization a breeze. <br />
                        Follow us on social media for updates and stay tuned for more details. <br />
                        Get ready to turn your wishes into reality!
                    </CAlert>
                </CRow>

                <CRow>
                    <br />
                </CRow>
            </CContainer>
        </div>
    );
};

export default dashboard;