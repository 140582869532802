import React, {useContext} from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import '../css/profilePage.css'
import { DashboardSidebar } from '../components/dashboardSidebar'
import {Stats} from '../components/stats'
import {Settings} from '../components/settings'
import { Profile } from '../components/profile'
import Dashboard from '../components/dashboard'
import Collections from '../components/collections'
import CollectionsEdit from '../components/collectionsEdit'
import Stores from '../components/stores'


export const ProfilePage = () => {

    const auth = useContext(AuthContext);

    return(
        <div>
            <div className="dcontainer-fluid">
                <div className="profilePage">
                    <div className="profilePageLeft">
                        <DashboardSidebar></DashboardSidebar>
                    </div>
                    <div className="profilePageRight">

                        <Route exact path="/profile/welcome">
                            <Dashboard></Dashboard>
                        </Route>

                        <Route exact path="/profile/collections">
                            <Collections></Collections>
                        </Route>

                        <Route exact path="/profile/collections/edit/:id">
                            <CollectionsEdit></CollectionsEdit>
                        </Route>

                        <Route exact path="/profile/stores">
                            <Stores></Stores>
                        </Route>

                        <Route exact path="/profile/info" id={auth.ip}>
                            <Profile />
                        </Route>

                        <Route exact path="/profile/settings">
                            <Settings />
                        </Route>

                        <Route exact path="/profile/stats">
                            <Stats />
                        </Route>

                        <Redirect to="/profile/welcome" />
                    </div>
                </div>
            </div>
        </div>
    )
}