import React, {useState, useContext} from 'react'
import '../css/loginPage.css'
import axios from 'axios'
import { AuthContext } from '../context/AuthContext'

export const RegisterPage = () => {

    const auth = useContext(AuthContext)

    
    const [form, setForm] = useState({
        name: '',
        telephone: '',
        siteLink:'',
        email: '',
        password: ''
    })

    const [mess, setMess] = useState({
        message: null
    })

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const registerHandler = event => {
        console.log(form, auth.ip)
        axios.post(`https://${auth.ip}/auth/register`, {
            name: form.name,
            telephone: form.telephone,
            siteLink: form.siteLink,
            email: form.email,
            password: form.password
        })
        .then(function (response) {
            if(response.data.message){
                setMess({
                    message: response.data.message
                })
            }
            if (response.status === 200) {
                window.location = "/" 
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    return(
        <div>
            {mess.message &&
                <div className="alertMessage">
                    {mess.message}
                </div>
            }

            <main className="loginMain">
                <div className="dcontainer">
                    <div className="loginContent register">
                        <div className="registerContent">
                            <h2 className="loginSubTitle">Welcome</h2>
                            <h2 className="loginTitle">Register your new account</h2>

                            <div className="loginForm">
                                <div className="LoginFormInput">
                                    <label>Name and Surname</label>
                                    <input type="text" name="name" onChange={changeHandler}/>
                                </div>
                                
                                <div className="LoginFormInput">
                                    <label>Telephone number</label>
                                    <input type="text" name="telephone" onChange={changeHandler}/>
                                </div>
                                
                                <div className="LoginFormInput">
                                    <label>Email*</label>
                                    <input type="text" name="email" onChange={changeHandler}/>
                                </div>
                                
                                <div className="LoginFormInput">
                                    <label>Password*</label>
                                    <input type="password" name="password" onChange={changeHandler}/>
                                </div>
                                
                                <div className="LoginFormInput">
                                    <label>Repeat your password</label>
                                    <input type="password" name="passwordRepeat" onChange={changeHandler}/>
                                </div>
                                
                                <input type="submit" className="loginFormSubmit" value="Register now" onClick={registerHandler} />
                                
                                <p className="loginFormRegP">Already have an account? <a className="loginFormRegLink" href="#/auth/login">Login now</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}