import React, {useContext, useState} from 'react'
import { AuthContext } from '../context/AuthContext'
import { CAvatar,CDropdownItem,CDropdownToggle,CDropdown,CDropdownMenu,CDropdownDivider,CBadge} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import {freeSet} from '@coreui/icons';

const RightNav = () => {
    
    const auth = useContext(AuthContext);

    const logOutHandler = () => {
        auth.logout()
    }

    return (
        <div className="rightNav">
            <div className="rightNavLeft">
                
            </div>

            <div className="rightNavRight">


                <div className="navBTNDropdown">
                    <CDropdown>
                        <CDropdownToggle className="avatarDropdown p-10 bg-transparent darkColor">
                            <CIcon customClassName="icon icon-xl darkColor" icon={freeSet.cilBell} />
                            <CBadge
                                className="border border-light p-1"
                                color="danger"
                                position="top-end"
                                shape="rounded-circle"
                                style={{left: "80% !important"}}
                            >
                                <span className="visually-hidden">New alerts</span>
                            </CBadge>
                        </CDropdownToggle>
                        
                        <CDropdownMenu>
                            <CDropdownItem>
                                We're thrilled to announce a special app demo that will change the way you create and manage your wishlists. <br/> 
                                Join us for an exclusive sneak peek of our revolutionary app. <br/>
                                Discover powerful features designed to make wishlist organization a breeze. <br/>
                                Follow us on social media for updates and stay tuned for more details. <br/>
                                Get ready to turn your wishes into reality!
                            </CDropdownItem>
                        </CDropdownMenu>
                    </CDropdown>

                    {/* <CDropdown>
                        <CDropdownToggle className="avatarDropdown p-10 bg-transparent darkColor">
                            <CIcon status="danger" customClassName="icon icon-xl darkColor" icon={freeSet.cilChatBubble} />
                        </CDropdownToggle>
                        
                        <CDropdownMenu>
                            <CDropdownItem>
                                Hey!
                            </CDropdownItem>
                        </CDropdownMenu>
                    </CDropdown> */}

                    <CDropdown>
                        <CDropdownToggle className="avatarDropdown p-10 bg-transparent">
                            <CAvatar color="primary" textColor="white">
                                P
                            </CAvatar>
                        </CDropdownToggle>

                        <CDropdownMenu>
                            <CDropdownItem href="#">Action</CDropdownItem>

                            <CDropdownDivider/>
                            <CDropdownItem href="#/profile/settings">
                                Settings
                            </CDropdownItem>

                            <CDropdownItem href="#" onClick={logOutHandler}>
                                Log out
                            </CDropdownItem>
                        </CDropdownMenu>
                    </CDropdown>
                </div>
                
            </div>
            
        </div>
    );
};

export default RightNav;