import React, {useContext, useState} from 'react'
import '../css/loginPage.css'
import axios from 'axios'
import { AuthContext } from '../context/AuthContext'

export const LoginPageMerchant = () => {

    const auth = useContext(AuthContext)
    
    const [form, setForm] = useState({
        email: '',
        password: ''
    })

    const [mess, setMess] = useState({
        message: null
    })
    
    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const loginHandler = () => {
        // console.log(form)
        axios.post(`https://${auth.ip}/auth`, {
            email: form.email.trim(),
            password: form.password
        })
        .then(function (response) {
            if(response.data.message){
                setMess({
                    message: response.data.message
                })
            }
            auth.login(response.data.token, response.data.userId, response.data.isAdmin)
        })
        .then(function(){
            const id = JSON.parse(localStorage.getItem('userData'))
            axios.post(`https://${auth.userId}/user/${id.userId}`)
            .then(function(response){
                // console.log(response.data)
                const res = JSON.stringify(response.data)
                sessionStorage.setItem('userData', res)
            })
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    return(
        <div>
            {mess.message &&
                <div className="alertMessage">
                    {mess.message}
                </div>
            }

            <main className="loginMain">
                <div className="dcontainer">
                    <div className="loginContent">
                        <div className="loginContentRight">

                            <h2 className="loginSubTitle">Welcome back</h2>
                            <h2 className="loginTitle">Login to your merchant account</h2> 

                            <div className="loginForm">
                                <div className="LoginFormInput">
                                    <label>Email</label>
                                    <input type="text" name="email" onChange={changeHandler}/>
                                </div>
                            
                                <div className="LoginFormInput">
                                    <label>Password</label>
                                    <input type="password" name="password" onChange={changeHandler}/>
                                </div>
                            
                                {/* <div className="loginFormRow">
                                    <div className="loginFormRight">
                                        <a href="space" className="loginFormResetPass">Forgot password?</a>
                                    </div>
                                </div> */}
                            </div>
                            
                            <input type="submit" className="loginFormSubmit" value="Login now" onClick={loginHandler}  /> 

                            <p className="loginFormRegP">Ready to start selling with fyby? <a className="loginFormRegLink" href="#/auth/register">Join free today.</a> </p>

                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}