import React from 'react'
import './index.css'
import {HashRouter as Router} from 'react-router-dom'
import {useRoutes} from './routes'
import {useAuth} from './hooks/auth.hook'
import { AuthContext } from './context/AuthContext'
import './css/mobile.css'
import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

export const App = () => {

  const {token, login, logout, userId, isAdmin, ip} = useAuth()
  const isAuthenticated = !!token
  const routes = useRoutes(isAuthenticated, isAdmin)

  return (
    <AuthContext.Provider value={{
      token, login, logout, userId, isAuthenticated, isAdmin, ip
    }}>
      <Router>
        {routes}
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
