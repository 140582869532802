import { React,useContext,useEffect, useState} from 'react';
import axios from 'axios'
import RightNav from './rightNav';
// import CIcon from '@coreui/icons-react';
// import {freeSet} from '@coreui/icons';
import{
    CRow, 
    CCol, 
    CContainer,
    CNavItem,
    CTabContent,
    CNavLink,
    CTabPane,
    CNav,
    CCardBody,
    CCardTitle,
    CCardSubtitle,
    CCardText,
    CCardLink,
    CCard,
    CButton,
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CFormLabel,
    CFormInput,
    CFormCheck,
    CFormTextarea
} from '@coreui/react';
import { AuthContext } from '../context/AuthContext'

const Collections = () => {
    const [activeKey, setActiveKey] = useState(1)
    const auth = useContext(AuthContext);

    const [items, setItems] = useState([])
    const [visible, setVisible] = useState(false)

    const [wishlist, setWishlist] = useState({
        title:'',
        public: 0,
        note:''
    })

    const changeHandler = event => {
        setWishlist({ ...wishlist, [event.target.name]: event.target.value })
    }

    const createHandler = () => {
        // console.log(form)
        axios.post(`https://${auth.ip}/wishlist/create`, {
            owner: auth.userId,
            title: wishlist.title,
            public: wishlist.public,
            note: wishlist.note
        })
        .then(function (response) {
            console.log(response);
            axios.post(`https://${auth.ip}/wishlist/find`,{
                owner: auth.userId
            })
            .then(function(response){
                setItems(response.data);
                setVisible(false);
                setWishlist({
                    title:'',
                    public: 0,
                    note:''
                })
            })
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const deleteHandler = (id) => {
        // console.log(id)
        axios.post(`https://${auth.ip}/wishlist/delete`, {
            _id: id
        })
        .then(function (response) {
            console.log(response);
            axios.post(`https://${auth.ip}/wishlist/find`,{
                owner: auth.userId
            })
            .then(function(response){
                setItems(response.data);
            })
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        axios.post(`https://${auth.ip}/wishlist/find`,{
            owner: auth.userId
        })
        .then(function(response){
            setItems(response.data);
        })
        console.log('asd' + auth.ip)
    }, [auth.ip])

    useEffect(() => {
        console.log(items)
    }, [items])

    useEffect(() => {
        console.log(wishlist)
    }, [wishlist])

    return (
        <div>
            <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
                <CModalTitle>Create new wishlist</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CRow>
                    <CCol>
                        <CFormLabel htmlFor="staticEmail2">
                        Wishlist title:
                        </CFormLabel>
                        <CFormInput
                            type="text"
                            name='title'
                            id="exampleFormControlInput1"
                            placeholder="..."
                            onChange={changeHandler}
                        />
                    </CCol>
                </CRow>
                <br/>
                <CRow>
                    <CCol>
                        <CFormCheck button={{ color: 'primary', variant: 'outline' }} type="radio" name="options-outlined" id="success-outlined" autoComplete="off" label="Public" defaultChecked onChange={(event) => setWishlist({ title:wishlist.title,public: 0,note: wishlist.note})}/>
                        <CFormCheck button={{ color: 'primary', variant: 'outline' }} type="radio" name="options-outlined" id="danger-outlined" autoComplete="off" label="Private" onChange={(event) => setWishlist({ title:wishlist.title,public: 1,note: wishlist.note})}/>
                    </CCol>
                </CRow>
                <br />
                <CRow>
                    <CCol>
                        <CFormLabel htmlFor="staticNote">
                        Wishlist note(optional):
                        </CFormLabel>
                        <CFormTextarea
                            id="staticNote"
                            name="note"
                            label="Example textarea"
                            onChange={changeHandler}
                            rows={3}
                        ></CFormTextarea>
                    </CCol>
                </CRow>
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => setVisible(false)}>
                Close
                </CButton>
                <CButton color="primary" onClick={createHandler}>Create</CButton>
            </CModalFooter>
            </CModal>
            {/* modals end */}
            <RightNav />

            <CContainer>

                

                <CRow>
                    <CCol>
                        <div className='wishlistTopBar'>
                            <div>
                                <h4>Your wishlists:</h4>
                            </div>

                            <div>
                                <CNav variant="tabs" role="tablist">
                                    <CNavItem>
                                        <CNavLink
                                        href="javascript:void(0);"
                                        active={activeKey === 1}
                                        onClick={() => setActiveKey(1)}
                                        >
                                        Public
                                        </CNavLink>
                                    </CNavItem>
                                    <CNavItem>
                                        <CNavLink
                                        href="javascript:void(0);"
                                        active={activeKey === 2}
                                        onClick={() => setActiveKey(2)}
                                        >
                                        Private
                                        </CNavLink>
                                    </CNavItem>
                                </CNav>
                            </div>

                            <div>
                                <CButton color="primary" onClick={() => setVisible(!visible)}>Create</CButton>
                            </div>
                        </div>
                    </CCol>
                </CRow>


                <CRow>
                <CCol>    
                    
                    <CTabContent>
                        <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 1}>
                            <br />

                            <CRow>
                                {items.map(item => {
                                    if(item.public == 0){
                                        return(
                                            <CCol sm={12} className="itemCartWishlsit" key={item._id}>
                                                <CCard>
                                                    <CCardBody>
                                                        <CCardTitle>{item.title}</CCardTitle>
                                                        <div className='Card_tag'>
                                                            {item.public == 0 && <span>Public</span> }
                                                            {item.public == 1 && <span>Private</span> }
                                                        </div>
                                                        <CCardText>
                                                            {item.note}
                                                        </CCardText>
                                                        <a target='_blank' href={`#/wishlist/${item._id}`} className='Card_open_btn' tooltip="Open">
                                                            <svg class="SVG_ICON" aria-hidden="true" xmlns="https://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                                                <path d="M4.09 7.586A1 1 0 0 1 5 7h13V6a2 2 0 0 0-2-2h-4.557L9.043.8a2.009 2.009 0 0 0-1.6-.8H2a2 2 0 0 0-2 2v14c.001.154.02.308.058.457L4.09 7.586Z"/>
                                                                <path d="M6.05 9 2 17.952c.14.031.281.047.424.048h12.95a.992.992 0 0 0 .909-.594L20 9H6.05Z"/>
                                                            </svg>
                                                        </a>

                                                        <a href={`#/profile/collections/edit/${item._id}`} className='Card_open_btn' tooltip="Edit">
                                                            <svg class="SVG_ICON" aria-hidden="true" xmlns="https://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                                                <path d="M12.687 14.408a3.01 3.01 0 0 1-1.533.821l-3.566.713a3 3 0 0 1-3.53-3.53l.713-3.566a3.01 3.01 0 0 1 .821-1.533L10.905 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V11.1l-3.313 3.308Zm5.53-9.065.546-.546a2.518 2.518 0 0 0 0-3.56 2.576 2.576 0 0 0-3.559 0l-.547.547 3.56 3.56Z"/>
                                                                <path d="M13.243 3.2 7.359 9.081a.5.5 0 0 0-.136.256L6.51 12.9a.5.5 0 0 0 .59.59l3.566-.713a.5.5 0 0 0 .255-.136L16.8 6.757 13.243 3.2Z"/>
                                                            </svg>
                                                        </a>

                                                        <a href={`#/wishlist/${item._id}`} className='Card_open_btn' tooltip="Share">
                                                            <svg class="SVG_ICON" fill="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                <path d="M17.5 3A3.5 3.5 0 0 0 14 7L8.1 9.8A3.5 3.5 0 0 0 2 12a3.5 3.5 0 0 0 6.1 2.3l6 2.7-.1.5a3.5 3.5 0 1 0 1-2.3l-6-2.7a3.5 3.5 0 0 0 0-1L15 9a3.5 3.5 0 0 0 6-2.4c0-2-1.6-3.5-3.5-3.5Z"/>
                                                            </svg>
                                                        </a>

                                                        <button className='Card_remove_btn' onClick={()=>{deleteHandler(item._id)}}>
                                                        <svg class="SVG_ICON" aria-hidden="true" xmlns="https://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                                            <path d="M17 4h-4V2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v2H1a1 1 0 0 0 0 2h1v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1a1 1 0 1 0 0-2ZM7 2h4v2H7V2Zm1 14a1 1 0 1 1-2 0V8a1 1 0 0 1 2 0v8Zm4 0a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0v8Z"/>
                                                        </svg>
                                                        </button>
                                                    </CCardBody>
                                                </CCard>
                                            </CCol>
                                        )
                                    }
                                })}
                            </CRow>
                        </CTabPane>

                        <CTabPane role="tabpanel" aria-labelledby="profile-tab" visible={activeKey === 2}>
                            <br />
                            
                            <CRow>
                                {items.map(item => {
                                    if(item.public == 1){
                                        return(
                                            <CCol sm={12} className="itemCartWishlsit" key={item._id}>
                                                <CCard>
                                                    <CCardBody>
                                                        <CCardTitle>{item.title} </CCardTitle>
                                                        <div className='Card_tag'>
                                                            {item.public == 0 && <span>Public</span> }
                                                            {item.public == 1 && <span>Private</span> }
                                                        </div>
                                                        <CCardText>
                                                            {item.note}
                                                        </CCardText>
                                                        <a target='_blank' href={`#/wishlist/${item._id}`} className='Card_open_btn' tooltip="Open">
                                                            <svg class="SVG_ICON" aria-hidden="true" xmlns="https://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                                                <path d="M4.09 7.586A1 1 0 0 1 5 7h13V6a2 2 0 0 0-2-2h-4.557L9.043.8a2.009 2.009 0 0 0-1.6-.8H2a2 2 0 0 0-2 2v14c.001.154.02.308.058.457L4.09 7.586Z"/>
                                                                <path d="M6.05 9 2 17.952c.14.031.281.047.424.048h12.95a.992.992 0 0 0 .909-.594L20 9H6.05Z"/>
                                                            </svg>
                                                        </a>

                                                        <a href={`#/profile/collections/edit/${item._id}`} className='Card_open_btn' tooltip="Edit">
                                                            <svg class="SVG_ICON" aria-hidden="true" xmlns="https://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                                                <path d="M12.687 14.408a3.01 3.01 0 0 1-1.533.821l-3.566.713a3 3 0 0 1-3.53-3.53l.713-3.566a3.01 3.01 0 0 1 .821-1.533L10.905 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V11.1l-3.313 3.308Zm5.53-9.065.546-.546a2.518 2.518 0 0 0 0-3.56 2.576 2.576 0 0 0-3.559 0l-.547.547 3.56 3.56Z"/>
                                                                <path d="M13.243 3.2 7.359 9.081a.5.5 0 0 0-.136.256L6.51 12.9a.5.5 0 0 0 .59.59l3.566-.713a.5.5 0 0 0 .255-.136L16.8 6.757 13.243 3.2Z"/>
                                                            </svg>
                                                        </a>

                                                        <a href={`#/wishlist/${item._id}`} className='Card_open_btn' tooltip="Share">
                                                            <svg class="SVG_ICON" fill="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                <path d="M17.5 3A3.5 3.5 0 0 0 14 7L8.1 9.8A3.5 3.5 0 0 0 2 12a3.5 3.5 0 0 0 6.1 2.3l6 2.7-.1.5a3.5 3.5 0 1 0 1-2.3l-6-2.7a3.5 3.5 0 0 0 0-1L15 9a3.5 3.5 0 0 0 6-2.4c0-2-1.6-3.5-3.5-3.5Z"/>
                                                            </svg>
                                                        </a>
                                                        
                                                        <button className='Card_remove_btn' onClick={()=>{deleteHandler(item._id)}} tooltip="Remove">
                                                        <svg class="SVG_ICON" aria-hidden="true" xmlns="https://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                                            <path d="M17 4h-4V2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v2H1a1 1 0 0 0 0 2h1v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1a1 1 0 1 0 0-2ZM7 2h4v2H7V2Zm1 14a1 1 0 1 1-2 0V8a1 1 0 0 1 2 0v8Zm4 0a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0v8Z"/>
                                                        </svg>
                                                        </button>
                                                    </CCardBody>
                                                </CCard>
                                            </CCol>
                                        )
                                    }
                                })}
                            </CRow>
                        </CTabPane>
                        
                    </CTabContent>
                </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export default Collections;