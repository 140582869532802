import React from 'react';
import RightNav from './rightNav';
import{
    CRow, 
    CCol, 
    CContainer,
    
} from '@coreui/react';

const Stores = () => {
    return (
        <div>
            <RightNav />
            
            <CContainer>
                <CRow>
                    <CCol>
                        <br />
                    </CCol>
                </CRow>

                <CRow>
                    <CCol>
                        No stores connected
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export default Stores;